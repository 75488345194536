<template>
  <div class="plan-container plan-container__query-builder">
    <div class="top">
      <p class="title">Create customised charts by querying your data</p>
      <div>
        <button style="float: right" class="button" @click="exportToCSV">
          Download CSV
        </button>
      </div>
      <div class="chart-types">
        <a @click="type = 'pie'" :class="{ selected: type === 'pie' }">
          <i class="fas fa-chart-pie"></i>
        </a>
        <a @click="type = 'bar'" :class="{ selected: type === 'bar' }">
          <i class="fas fa-chart-bar"></i>
        </a>
        <a @click="type = 'scatter'" :class="{ selected: type === 'scatter' }">
          <i class="fas fa-chart-line"></i>
        </a>
      </div>
    </div>
    <div class="query-builder">
      <vue-query-builder
        :labels="queryLabels"
        :rules="queryRules"
        v-model="query"
        :maxDepth="1"
      ></vue-query-builder>
    </div>
    <div class="graphing" ref="graph-query"></div>
  </div>
</template>

<script>
/* global Plotly */
import { mapGetters, mapActions } from 'vuex'
import OutcomesMixin from '@/_mixins/outcome-formatter.mixin'
// import { format } from 'date-fns'
import VueQueryBuilder from 'vue-query-builder'
import jsonexport from 'jsonexport'
import { labelOrder } from '../../style/gradingValues'

const graphFont = {
  family: 'AllRoundGothicBook',
  size: '16',
  color: 'white',
}

const operators = {
  'Equal To': (x, y) =>
    x >= new Date(y).setHours(0, 0, 0, 0).valueOf() &&
    x <= new Date(y).setHours(24, 59, 59, 999).valueOf(),
  'Not Equal To': (x, y) =>
    x <= new Date(y).setHours(0, 0, 0, 0).valueOf() ||
    x >= new Date(y).setHours(24, 59, 59, 999).valueOf(),
  'Less Than': (x, y) => x < new Date(y).setHours(0, 0, 0, 0).valueOf(),
  'Less Than Or Equal To': (x, y) =>
    x <= new Date(y).setHours(24, 59, 59, 999).valueOf(),
  'Greater Than': (x, y) => x > new Date(y).setHours(24, 59, 59, 999).valueOf(),
  'Greater Than Or Equal To': (x, y) =>
    x >= new Date(y).setHours(0, 0, 0, 0).valueOf()
}

export default {
  name: 'QueryData',
  components: { VueQueryBuilder },
  mixins: [OutcomesMixin],
  data() {
    return {
      type: 'pie',
      layout: {
        legend: {
          traceorder: 'normal',
          font: {
            family: 'AllRoundGothicBook',
            size: '16',
          },
        },
      },
      queryLabels: {
        matchTypes: [
          { id: 'all', label: 'Match all (AND)' },
          { id: 'any', label: 'Match any (OR)' },
        ],
        addRule: 'Add filter',
        addGroup: 'Add filter group',
      },
      queryRules: [
        {
          type: 'select',
          id: 'outcome',
          label: 'Overall grading',
          choices: [
            { label: 'Gold', value: 'Gold' },
            { label: 'Silver', value: 'Silver' },
            { label: 'Bronze', value: 'Bronze' },
          ],
        },
        {
          type: 'select',
          id: 'theme',
          label: 'Theme',
          choices: [
            { label: 'Timeliness & Quality', value: 1 },
            { label: 'Meeting Needs', value: 2 },
            { label: 'Outcomes', value: 3 },
            { label: 'Provision', value: 4 },
            { label: 'Post-annual review', value: 5 },
            { label: 'Outcomes monitoring', value: 6 },
          ],
        },
        {
          type: 'numeric',
          id: 'completedAt',
          label: 'Review completed',
          inputType: 'date',
          operators: Object.keys(operators),
        },
        {
          type: 'numeric',
          id: 'createdAt',
          label: 'Review assigned',
          inputType: 'date',
          operators: Object.keys(operators),
        },
        {
          type: 'numeric',
          id: 'createdAt',
          label: 'Plan Uploaded',
          inputType: 'date',
          operators: Object.keys(operators),
        },
      ],
      query: {
        children: [
          //   {
          //   query: null,
          //   type: null
          // }
        ],
        logicalOperator: 'all',
      },
      filters: [],
    }
  },
  watch: {
    'query.children': {
      handler() {
        this.filters = this.query.children.slice()
        this.refreshGraphs()
        // this.query.children.forEach((filter, index) => {
        // this.$set(this.query.children, index, filter)
        // console.log(`this[${filter.query.rule}] = ${filter.query.value}`)
        // })
      },
      deep: true,
    },
    type() {
      this.$forceUpdate()
      this.$nextTick(() => this.refreshGraphs())
    },
    graphDataFromQuery() {
      this.refreshGraphs()
    },
  },
  computed: {
    ...mapGetters({
      assignments: 'assignment/assignments',
      reviews: 'assignment/reviews',
    }),
    completedReviews() {
      return this.reviews
        .filter((a) => a?.completedAt)
        .filter((a) => !a?.assignment?.archived)
        .filter((a) => a?.outcome)
    },
    // assignmentData () {
    //   // return this.assignments.filter(a => a.completedAt)
    //   return this.reviews.filter(a => a.completedAt).map(r => r.assignment)
    // },
    needs() {
      return new Set(
        this.completedReviews.map((a) => a.assignment.plan.primaryNeed)
      )
    },
    inputs() {
      return new Set(
        this.completedReviews.map((a) => a.assignment.plan.currentSocialCare)
      )
    },
    keyStages() {
      return new Set(
        this.completedReviews.map((a) => a.assignment.plan.keyStage)
      )
    },
    settingTypes() {
      return new Set(
        this.completedReviews.map((a) => a.assignment.plan.settingType)
      )
    },
    settingNames() {
      return new Set(
        this.completedReviews.map((a) => a.assignment.plan.settingName)
      )
    },
    caseOfficers() {
      return new Set(
        this.completedReviews.map((a) => a.assignment.plan.caseOfficer)
      )
    },
    teamRegions() {
      return new Set(
        this.completedReviews.map((a) => a.assignment.plan.teamRegion)
      )
    },
    primaryExportData() {
      return this.graphDataFromQuery.map((a) => {
        delete a.assignment.id
        delete a.assignment.organisationId
        return {
          ...a.assignment,
        }
      })
    },
    graphDataFromQuery() {
      const hasOutComeQuery = this.filters.some(
        (f) => f.query.rule === 'outcome'
      )
      const hasThemeQuery = this.filters.some((f) => f.query.rule === 'theme')
      const hasDateQuery = this.filters.some(
        (f) => f.query.rule === 'completedAt'
      )
      const hasDateUploadedQuery = this.filters.some(
        (f) => f.query.rule === 'createdAt'
      )
      // console.log({ hasOutComeQuery, hasDateQuery, hasThemeQuery })

      const filters = this.filters.map(({ query }) => {
        return {
          rule: query.rule,
          value: query.value,
          operator: query.operator || null,
        }
      })

      let reviews = [...this.completedReviews]

      const excludedRules = ['outcome', 'completedAt', 'createdAt', 'theme']
      filters.forEach((filter) => {
        if (!excludedRules.includes(filter.rule)) {
          reviews = reviews.filter(
            (review) => review.assignment.plan[filter.rule] === filter.value
          )
        } else if (hasOutComeQuery && filter.rule === 'outcome') {
          reviews = reviews.filter(
            (review) =>
              this.determineSectionOutcome(review.outcome) ===
              hasOutComeQuery.query.value
          )
        } else if (hasThemeQuery && filter.rule === 'theme') {
          reviews = reviews.map((review) => {
            const filteredTheme = review.answers.find(
              (theme) => theme.theme === filter.value
            )
            return {
              ...review,
              outcome: this.determineSectionOutcome(filteredTheme.outcome),
              completedAt: review.completedAt,
              theme: {
                outcome: this.determineSectionOutcome(filteredTheme.outcome),
                theme: filteredTheme.theme,
              },
            }
          })
        } else if (
          (hasDateQuery && filter.rule === 'completedAt') ||
          (hasDateQuery && filter.rule === 'createdAt') ||
          (hasDateUploadedQuery && filter.rule === 'createdAt')
        ) {
          const filterDate = new Date(filter.value)
          reviews = reviews.filter((review) => {
            const reviewDate = new Date(review[filter.rule])
            return operators[filter.operator](
              reviewDate.valueOf(),
              filterDate.valueOf()
            )
          })
        }
      })

      console.log(reviews)
      return reviews
    },
    queryChartData() {
      const outcomes = this.graphDataFromQuery.map((a) =>
        Number.isNaN(Number(a.outcome))
          ? a.outcome
          : this.determineOutcome(a.outcome)
      )
      const keys = new Set(outcomes)
      const values = Array.from(keys)
        .flatMap((k) => {
          return {
            key: k,
            data: outcomes.filter((o) => o === k).length,
          }
        })
        .reduce((accumulated, item) => {
          return {
            ...accumulated,
            [item.key]: item.data,
          }
        }, {})

      const ordered = {}
      Object.keys(values)
        .sort((a, b) => labelOrder.indexOf(a) - labelOrder.indexOf(b))
        .forEach((k) => (ordered[k] = values[k]))
      return ordered
    },
    queryTrace() {
      const data =
        this.type === 'pie'
          ? {
              values: Object.values(this.queryChartData),
              labels: Object.keys(this.queryChartData),
              hole: 0.6,
            }
          : {
              x: Object.keys(this.queryChartData),
              y: Object.values(this.queryChartData),
            }
      const markers =
        this.type === 'pie'
          ? {
              colors: Object.keys(this.queryChartData).map(
                (k) => this.colorLookup[k]
              ),
              line: {
                width: 10,
                color: '#FFF',
              },
            }
          : {
              color: Object.keys(this.queryChartData).map(
                (k) => this.colorLookup[k]
              ),
            }
      return [
        {
          type: this.type,
          ...data,
          sort: false,
          textfont: graphFont,
          hoverlabel: {
            bordercolor: Object.keys(this.graphDataFromQuery).map(
              (k) => this.colorLookup[k]
            ),
            font: graphFont,
          },
          marker: {
            ...markers,
          },
        },
      ]
    },
  },
  methods: {
    ...mapActions({ fetchAssignments: 'assignment/fetchAssignments' }),
    pushQueryRules() {
      const settingNames = {
        type: 'select',
        id: 'settingName',
        label: 'Setting name',
        choices: Array.from(this.settingNames).map((t) => {
          return { label: t, value: t }
        }),
      }
      const settingTypes = {
        type: 'select',
        id: 'settingType',
        label: 'Setting type',
        choices: Array.from(this.settingTypes).map((t) => {
          return { label: t, value: t }
        }),
      }
      const inputs = {
        type: 'select',
        id: 'currentSocialCare',
        label: 'Social Care Input',
        choices: Array.from(this.inputs).map((t) => {
          return { label: t, value: t }
        }),
      }
      const needs = {
        type: 'select',
        id: 'primaryNeed',
        label: 'Primary area of need',
        choices: Array.from(this.needs).map((t) => {
          return { label: t, value: t }
        }),
      }
      const stages = {
        type: 'select',
        id: 'keyStage',
        label: 'Key Stage',
        choices: Array.from(this.keyStages).map((t) => {
          return { label: t, value: t }
        }),
      }
      const officers = {
        type: 'select',
        id: 'caseOfficer',
        label: 'SEN Case Officer',
        choices: Array.from(this.caseOfficers).map((t) => {
          return {
            label: t === undefined || t === null ? 'none assigned' : t,
            value: t,
          }
        }),
      }
      const teamRegions = {
        type: 'select',
        id: 'teamRegion',
        label: 'Team / Region',
        choices: Array.from(this.teamRegions).map((t) => {
          return {
            label: t === undefined || t === null ? 'none assigned' : t,
            value: t,
          }
        }),
      }
      this.queryRules.push(settingNames)
      this.queryRules.push(settingTypes)
      this.queryRules.push(inputs)
      this.queryRules.push(needs)
      this.queryRules.push(stages)
      this.queryRules.push(officers)
      this.queryRules.push(teamRegions)
    },
    loadGraphs() {
      const queryGraph = this.$refs['graph-query']
      Plotly.newPlot(queryGraph, this.queryTrace, this.layout, {
        displaylogo: false,
        responsive: true,
        autosizable: true,
      })
      // .then(plot => console.log({ plot }))
    },
    refreshGraphs() {
      const annotations = [
        {
          text: 'No information available for this selection',
          xref: 'paper',
          yref: 'paper',
          showarrow: false,
          font: {
            family: 'AllRoundGothicBook',
            size: '32',
          },
        },
      ]
      if (this.graphDataFromQuery.length === 0) {
        this.layout.annotations = annotations
      } else {
        delete this.layout.annotations
      }
      Plotly.react(
        this.$refs['graph-query'],
        {
          data: this.queryTrace,
          layout: this.layout,
          config: { responsive: true, autosizable: true, editable: true },
        },
        {
          transition: {
            duration: 500,
            easing: 'cubic-in-out',
          },
          frame: {
            duration: 500,
          },
        }
      )
    },
    async exportToCSV() {
      const data = this.primaryExportData
      const doc = await jsonexport(data)
      const newBlob = new Blob([doc])
      const blobUrl = window.URL.createObjectURL(newBlob)
      const link = document.createElement('a')
      link.href = blobUrl
      link.setAttribute('download', 'report.csv')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(doc)
    },
    resetGraphs() {},
  },
  async mounted() {
    if (!this.assignments.length) {
      await this.fetchAssignments()
    }
    this.pushQueryRules()
    this.$nextTick(() => this.loadGraphs())
    // this.loadGraphs()
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/components.scss';
@import '@/assets/styles/sections.scss';
@import '@/assets/styles/modal.scss';

.graphing {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  // grid-area: graph;
}

.query-builder {
  margin-top: 1rem;
  max-height: 350px;
  overflow-y: auto;
  // padding: 0.5rem 1rem;
}

.query-graph-container {
  height: 100;
}

.top {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.title {
  grid-area: unset;
  height: 2rem;
}

.chart-types {
  padding: 0.5rem;
  background-color: #00000008;
  a {
    color: unset;
    padding: 0 0.5rem;
    &:hover {
      cursor: pointer;
      color: $good-color;
    }
    &.selected {
      color: $good-color;
    }
  }
}
</style>

<style lang="scss">
div.vqb-group-heading.card-header {
  display: none;
}

select.form-control.mr-2 > button.btn.btn-secondary {
  color: red;
}
// #vqb-match-type.form-control,
// .form-control.mr-2 {
//   display: inline-flex;
//   outline: 0;
//   appearance: none;
//   height: 2.5rem;
//   background-color: #fff;
//   border-color: #dbdbdb;
//   border-radius: 4px;
//   color: #363636;
//   max-width: 100%;
//   min-width: 150px;
//   width: auto;
//   align-items: center;
//   justify-content: center;
//   font-size: 1rem;
//   padding: 0.25rem 1rem;
//   line-height: 1.5;
// }

// #vqb-match-type.form-control:focus,
// .form-control.mr-2 {
//   min-width: 150px;
//   width: auto;
// }
</style>
