<template>
  <div class="comments-container">
    <SectionHeader title="Theme Comments" />
    <div class="dateFilter">
      From:
      <input type="date" name="search" v-model="dateRange.start" />
      To:
      <input type="date" name="search" v-model="dateRange.end" />
    </div>
    <div class="section-list">
      <div
        class="section-item"
        v-for="section in 6"
        :key="section"
        @click="selectSection(section)"
      >
        <p class="section-item__name">{{ themeTitle[section - 1] }}</p>
        <p class="section-item__count">
          {{
            commentCountBySection[section]
              ? commentCountBySection[section].count
              : "No"
          }}
          comments
        </p>
        <input
          type="checkbox"
          name="check"
          id=""
          :checked="selectedSection.find((s) => s === section)"
        />&nbsp;
        <label for="check">Add to report</label>
      </div>
    </div>
    <div class="results-table">
      <table>
        <thead></thead>
        <tbody>
          <tr v-for="id in selectedSection" :key="id">
            <p class="section-id">{{ themeTitle[id - 1] }}</p>
            <ul>
              <li
                v-for="(comment, index) in commentCountBySection[id].comments"
                :key="index"
              >
                {{ comment.text }}
              </li>
            </ul>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'

import { mapGetters, mapActions } from 'vuex'
import { format } from 'date-fns'
import { names } from '../../style/gradingValues'

export default {
  name: 'ThemeComments',
  components: { SectionHeader },
  data () {
    return {
      showAuditData: false,
      audit: { planId: { internalId: null, ehcpId: null } },
      names,
      selectedSection: [],
      dateRange: {
        start: 0,
        end: 0
      }
    }
  },
  filters: {
    formatDate (val) {
      return format(new Date(val), 'PPP')
    }
  },
  computed: {
    ...mapGetters({
      assignments: 'assignment/assignments',
      users: 'organisation/users',
      reviews: 'assignment/reviews'
    }),
    themeTitle () {
      return [
        'Timeliness & Quality',
        'Meeting Needs',
        'Outcomes',
        'Provision',
        'Post-annual review',
        'Outcomes monitoring'
      ]
    },
    reviewReports () {
      const reviews =  this.reviews.filter(review => review.outcome && review.completedAt && !review?.assignment?.archived)
        .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))
        return reviews
    },
    reviewsFrom () {
      return new Date(this.dateRange.start)
    },
    reviewsTo () {
      return new Date(this.dateRange.end)
    },
    commentCountBySection () {
      const counts = {
        1: {
          count: 0,
          comments: []
        }
      }
      // loop through completed audits
      this.reviewReports.forEach(review => {
        // filter out sections that have comments
        const comments = review.answers.filter(theme => theme.comments)
        const reviewCompleted = new Date(review.completedAt)
        const completedDateValue = reviewCompleted.valueOf()
        comments.forEach(theme => {
          if (!counts[theme.theme]) {
            counts[theme.theme] = {
              count: 0,
              comments: []
            }
          }
          let inDate = true
          if (this.reviewsFrom.valueOf() > 0) {
            if (completedDateValue <= this.reviewsFrom.valueOf()) {
              inDate = false
            }
          }
          if (this.reviewsTo.valueOf() > 0) {
            if (completedDateValue >= this.reviewsTo.setHours(24, 59, 59, 999).valueOf()) {
              inDate = false
            }
          }
          if (inDate) {
            counts[theme.theme].count++
            counts[theme.theme].comments.push({ text: theme.comments, date: review.completedAt })
          }
        })
      })
      
      return counts
    }
  },
  methods: {
    ...mapActions({
      fetchUsers: 'organisation/fetchUsers',
      fetchAssignments: 'assignment/fetchAssignments'
    }),
    numComments (section) {
      return 5
    },
    selectSection (section) {
      if (this.selectedSection.find(s => s === section)) {
        this.selectedSection = this.selectedSection.filter(s => s !== section)
        return
      }
      this.selectedSection.push(section)
    },
    color (grade) {
      const style = {
        color: 'white',
        background: null
      }
      style.background =
        grade.determination && (grade.id !== 'qa' || grade.id !== 'additional')
          ? this.names[grade.determination].color
          : '#80C088'
      return style
    }
  },
  async mounted () {
    if (!this.reviews.length) {
      await this.fetchAssignments()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

.field {
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  label {
    font-weight: 600;
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
  }
}

.section-list {
  grid-area: main;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

.section-item {
  cursor: pointer;
  margin: 0.5rem;
  padding: 0.5rem;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  transition: transform 150ms ease-in-out;
  &__name {
    font-weight: 600;
  }
  &__count {
    font-style: italic;
  }
  &:hover {
    transform: scale(1.025);
  }
}

.section-id {
  font-weight: 600;
  font-style: italic;
}
</style>
