<template>
  <div class="reports-data">
    <SectionHeader
      title="Dashboard"
      subtitle="Overall grading of Annual Reviews"
    />
    <div class="chart-options">
      <input type="checkbox" id="split-graph" @change="didToggleSplitData" />
      <label for="split-graph">Split chart by Moderated/Unmoderated</label>
    </div>
    <div class="graphing" :ref="chart.uuid"></div>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import { mapGetters } from 'vuex'
import { colorLookup, labelOrder } from '../../style/gradingValues'
import { outcomeGrades, formatGrades, getGradingColor  } from '../../_helpers/grading'


const graphFont = {
  family: 'AllRoundGothicBook',
  size: '16',
  color: 'white'
}

export default {
  name: 'Default',
  components: { SectionHeader },
  data () {
    return {
      chart: {
        uuid: "graph-count",
        layout: {
          legend: {
            traceorder: 'normal',
            font: {
              family: 'AllRoundGothicBook',
              size: '16'
            }
          },
          grid: {
            rows: 1,
            columns: 1
          }
        }
      },
      canShowSplitData: false
    }
  },
  watch: {
    reviews: {
      handler: function () {
        Plotly.react(
          this.$refs[this.chart.uuid],
          this.traces,
          this.chart.layout,
        )
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters({ reviews: 'assignment/reviews' }),
    //TODO: Remove this object once final outcome functionality is integrated
    // fakeCompletedReviews () {
    //   return this.reviews.map(review => ({
    //     ...review,
    //     completedAt: '2021-03-12T08:26:31.806Z',
    //     outcome: Math.floor(Math.random() * 15) + 1
    //   }))
    // },
    completedReviews () {
      return this.reviews.filter(r => r?.completedAt && r?.outcome && !r?.assignment?.archived)
    },
    moderatedReviews () {
      const uniques = new Set()
      const multiples = new Set()
      this.completedReviews.forEach(review => {
        if (uniques.has(review.assignment.planId)) {
          multiples.add(review.assignment.planId)
          uniques.delete(review.assignment.planId)
        } else {
          uniques.add(review.assignment.planId)
        }
      })
      return [...multiples]
    },
    gradingData () {
      const outcomes = this.canShowSplitData
        ? this.completedReviews.filter(review => !this.moderatedReviews.includes(review.assignment.planId)).map(a => this.determineOutcome(a.outcome))
        : this.completedReviews.map(a => this.determineOutcome(a.outcome))
      
      
      const keys = new Set(outcomes)
      const values = Array.from(keys)
        .flatMap(k => ({
          data: outcomes.filter(o => o === k).length,
          key: k
        }))
        .reduce((accumulated, item) => {
          return {
            ...accumulated,
            [item.key]: item.data
          }
        }, {})
      // return values
      const ordered = {}
      Object.keys(values)
        .sort((a, b) => labelOrder.indexOf(a) - labelOrder.indexOf(b))
        .forEach(k => (ordered[k] = values[k]))
      return ordered
    },
    moderationData () {
      const outcomes = this.completedReviews.filter(review => this.moderatedReviews.includes(review.assignment.planId)).map(a => this.determineOutcome(a.outcome))
      const keys = new Set(outcomes)
      const values = Array.from(keys)
        .flatMap(k => ({
          data: outcomes.filter(o => o === k).length,
          key: k
        }))
        .reduce((accumulated, item) => {
          return {
            ...accumulated,
            [item.key]: item.data
          }
        }, {})
      // return values
      const ordered = {}
      Object.keys(values)
        .sort((a, b) => labelOrder.indexOf(a) - labelOrder.indexOf(b))
        .forEach(k => (ordered[k] = values[k]))
      return ordered
    },
    traces () {
      const data = [{
        values: Object.values(this.gradingData),
        labels: Object.keys(this.gradingData),
        type: 'pie',
        sort: false,
        textfont: graphFont,
        hoverlabel: {
          font: graphFont
        },
        name: 'Not moderated',
        domain: {
          row: 0,
          column: 0
        },
        marker: {
          colors: Object.keys(this.gradingData).map(k => (colorLookup[k])),
          line: {
            width: 10,
            color: '#FFF'
          }
        }
      }]
      if (this.canShowSplitData) {
        data.push({
          values: Object.values(this.moderationData),
          labels: Object.keys(this.moderationData),
          type: 'pie',
          sort: false,
          textfont: graphFont,
          hoverlabel: {
            font: graphFont
          },
          name: 'Moderated',
          domain: {
            row: 0,
            column: 1
          },
          marker: {
            colors: Object.keys(this.moderationData).map(k => (colorLookup[k])),
            line: {
              width: 10,
              color: '#FFF'
            }
          }
        })
      }

      return data
    },
    // moderationTrace () {
    //   return this.orderedAndFiltered.find(a => a.id !== assignment.id && a.plan.id === assignment.plan.id) !== undefined
    // }
  },
  methods: {
    determineOutcome (val) {
      const gradingObj = outcomeGrades
      let resultColor = 'Bronze'

      Object.keys(gradingObj).forEach((grade) => {
        if (gradingObj[grade].min <= val && val <= gradingObj[grade].max) {
            resultColor = grade
        }
      })

      return resultColor
    },
    didToggleSplitData () {
      this.canShowSplitData = !this.canShowSplitData
      this.chart.layout.grid.columns = this.canShowSplitData
        ? 2
        : 1
      // Plotly.update(this.$refs[this.chart.uuid], this.traces, this.chart.layout)
      Plotly.newPlot(
        this.$refs[this.chart.uuid],
        this.traces,
        this.chart.layout,
        { displaylogo: false }
      )
    }
  },
  mounted () {
    Plotly.newPlot(
      this.$refs[this.chart.uuid],
      this.traces,
      this.chart.layout,
      { displaylogo: false }
    )
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";

.reports-data {
  height: 100%;
  width: 100%;
}

.chart-options {
  padding: 0.25rem;
  line-height: 1;
  label {
    margin: unset;
    margin-left: 0.25rem;
  }
}

.graphing {
  height: calc(100% - 1rem);
  width: 100%;
}
</style>
